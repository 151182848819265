import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";
import GoogleMaps from "@/helpers/googlemaps";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import "./styles/main.css";

const app = createApp(App);

const isLive =
  window.location.href.indexOf("localhost") === -1 &&
  window.location.href.indexOf("ngrok") === -1 &&
  window.location.href.indexOf("loca.lt") === -1 &&
  process.env.NODE_ENV !== "development";

if (isLive) {
  app.use(VueGtag, {
    config: {
      id: "G-RQ48XMVWXN",
    },
  });
}

app.use(GoogleMaps, {
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
});

Sentry.init({
  app,
  environment: isLive ? "production" : "development",
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "dev-onboarding.mabletherapy.com",
        "onboarding.mabletherapy.com",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.25,
});

app.use(router);
app.mount("#app");
