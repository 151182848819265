const _hsq = (window._hsq = window._hsq || []);

export function updateContactDetails(email: string, data: any) {
  _hsq.push([
    "identify",
    {
      email,
      ...objectKeysCamelCaseToSnakeCase(data || {}),
    },
  ]);
}

export function saveContactQuestionAnswer(
  email: string,
  question: string,
  answer: string | (string | number)[]
): void {
  const questionKey = kebabCaseToSnakeCase(question);
  const answerString = Array.isArray(answer) ? answer.join(";") : answer;
  updateContactDetails(email, { [questionKey]: answerString });
}

export function getHubspotOnboardingFormQuestions(
  practise: string,
  questions: string[]
): string[] {
  return questions.map((question) =>
    kebabCaseToSnakeCase(`${practise} ${question}`)
  );
}

export function getFormQuestionsFromHubspotData(
  practise: string,
  questions: string[],
  answers: { [key: string]: string }
): { [key: string]: string | string[] } {
  //map questions,
  const snakeCasePractise = `${kebabCaseToSnakeCase(practise)}_`;
  const form: { [key: string]: string } = Object.entries(answers).reduce(
    (answeredQuestions, [question, answer]) => {
      const questionId = snakeCaseToKebabCase(
        question.replace(snakeCasePractise, "")
      );
      if (!answer || !questions || !questions.includes(questionId)) {
        return answeredQuestions;
      }
      const formattedAnswer = answer.includes(";") ? answer.split(";") : answer;
      return { ...answeredQuestions, [questionId]: formattedAnswer };
    },
    {}
  );

  return form;
}

function snakeCaseToKebabCase(value: string) {
  return value.replaceAll(/(_)/g, "-");
}

function kebabCaseToSnakeCase(value: string) {
  return value.replaceAll(/( )|(-)/g, "_");
}

function objectKeysCamelCaseToSnakeCase(object: { [key: string]: string }) {
  const convertedObject = {} as { [key: string]: string };
  const convertToSnakeCase = (str: string) =>
    str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  Object.keys(object).forEach((key: string) => {
    convertedObject[convertToSnakeCase(key)] = object[key];
  });
  return convertedObject;
}
