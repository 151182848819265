import dayjs from "dayjs";

import {
  OnboardingFormQuestions,
  SlideCheckBoxGroupDescription,
  PractiseDetails,
} from "@/types";

export function capitalizeString(str: string) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export function getKeyDifficultyTotalScores(
  scores: { [key: string]: { [key: string]: number } },
  scoreDefault: { [key: string]: number }
) {
  return Object.values(scores).reduce(
    (totalScores, questionScores) => {
      const scores = Object.entries(questionScores);
      const newTotal = { ...totalScores };
      for (let i = 0; i < scores.length; i++) {
        const [key, score] = scores[i];
        newTotal[key] += score;
      }
      return newTotal;
    },
    { ...scoreDefault }
  );
}

export function mapKeyDifficultiesToRequiredSpecialities(
  keyDifficultyScores: { [key: string]: number },
  keyDifficultyIdMap: PractiseDetails["specialities"]
): { [key: number]: number } {
  return Object.entries(keyDifficultyScores).reduce(
    (
      requiredSpecialities: { [key: number]: number },
      [id, score]: [string, number]
    ) => ({
      ...requiredSpecialities,
      [keyDifficultyIdMap[id].id]: score,
    }),
    {}
  );
}

// this formats the answers to save as a json object - it converts it to the structure of a mable questionnaire.
export function addOptionsToAnswers(
  answers: { [key: string]: any },
  questions: OnboardingFormQuestions,
  practiseId: number
) {
  const answeredQuestions = Object.keys({ ...answers });
  const questionsAndAnswers = answeredQuestions
    .map((questionId) => {
      const formQuestionDesc = questions[questionId]
        ?.description as SlideCheckBoxGroupDescription;
      if (!formQuestionDesc) {
        return;
      }
      const question = formQuestionDesc?.label;
      const answerGiven = answers[questionId];
      const formQuestionOptions = formQuestionDesc.options || [];
      const options = formQuestionOptions.reduce(
        (options, option) => ({
          ...options,
          [option.value]: option.label,
        }),
        {}
      );
      // formatted to match Mable questionnaire builder structure.
      const formattedAnswer = {
        question,
        answerGiven,
        options,
        optionValues: {},
      };
      // Not a multiple choice answer
      if (!formQuestionDesc.options) {
        return {
          ...formattedAnswer,
          type: {
            type: "text-area",
            readableType: "Paragraph",
          },
        };
      }

      return {
        ...formattedAnswer,
        type: {
          type: "checkbox",
          hasOptions: true,
          tallyable: false,
          readableType: "Multiple Choice",
        },
      };
    })
    .filter((item) => item);

  return {
    title: "Onboarding Questions",
    questions: questionsAndAnswers,
    practiseId,
    tallyable: false,
  };
}

export function mapQuestionsToAnswers(
  answers: { [key: string]: any },
  questions: OnboardingFormQuestions
) {
  const answeredQuestions = Object.keys({ ...answers });

  const questionsAndAnswers: { [key: string]: string[] } = {};

  answeredQuestions.forEach((questionId, index) => {
    const formQuestionDesc = questions[questionId]
      ?.description as SlideCheckBoxGroupDescription;
    const question = formQuestionDesc?.label;
    const questionAnswer = answers[questionId];
    if (!formQuestionDesc.options) {
      questionsAndAnswers[`${index + 1}. ${question}`] = questionAnswer;
      return;
    }

    const answersGiven = formQuestionDesc?.options.reduce(
      (options: string[], option: { label: string; value: string }) =>
        option && questionAnswer.indexOf(option.value) > -1
          ? [...options, option.label]
          : options,
      []
    );

    questionsAndAnswers[`${index + 1}. ${question}`] = answersGiven || [];
  });
  return questionsAndAnswers;
}

export function getAgeFromDOBString(dobString: string) {
  const dob = dayjs(dobString);
  const now = dayjs();
  const age = now.diff(dob, "years");
  const patientAge = isNaN(age) ? null : Math.floor(age);
  return patientAge || 0;
}
