<template>
  <div
    id="loading-animation-element"
    :class="loaderType === 'm-loader' && 'default-loader'"
  />
</template>
<script>
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  props: {
    loaderType: {
      type: String,
      default: "m-loader",
    },
  },
  setup(props) {
    onMounted(() => {
      const loadingAnimationElement = document.getElementById(
        "loading-animation-element"
      );

      if (loadingAnimationElement) {
        lottie.loadAnimation({
          container: loadingAnimationElement, // the dom element that will contain the animation
          renderer: "svg",
          loop: true,
          autoplay: true,
          path: `${_LOADING_ANIMATION_PATH_}/${props.loaderType}.json`, // the path to the animation json
        });

        setTimeout(() => {
          loadingAnimationElement.classList.add("fade-in");
        }, 10);
      }
    });
  },
});
</script>

<style scoped>
.default-loader {
  width: 100px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.fade-in {
  opacity: 1;
}
</style>
